.menuPage {
  background-color: black;
  overflow: hidden;
}
.menuPage > .menuTop {
  /* background-image: url(../../img/menuImages/9_resized.jpg);
  background-size: cover;
  background-color: black;
  background-repeat: no-repeat;
  background-position: bottom left; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
}
.menuPage > .menuTop > .blurLoad {
  width: 100%;
  /* height: calc(100vh - 150px); */
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom left;
  top: 0;
  background-color: black;
}
.menuPage > .menuTop > .blurLoad > img {
  width: 100%;
  height: 100%;
  opacity: 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 300ms ease-in-out;
}
.menuPage > .menuTop > .blurLoad > .loaded {
  opacity: 0.8;
}
.menuPage > .menuTop > div {
  z-index: 1;
}
.menuPage > .menuTop > div > h1 {
  z-index: 1;
  text-align: center;
  color: white;
  font-size: 130px;
  font-family: TheBoldFont;
}
.menuPage > .menuTop > div > p {
  display: flex;
  gap: 15px;
}
.menuPage > .menuTop > div > p > a {
  z-index: 1;
  color: white;
  font-family: PoppinsBold;
  font-size: 30px;
  transition: color 300ms ease-in-out;
}
.menuPage > .menuTop > div > p > a:hover {
  color: var(--accent-color);
}
.menuPage > .menuList {
  padding-block: 70px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.menuPage > .menuList > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
}
.menuPage > .menuList > div > h2 {
  color: white;
  font-family: PoppinsBold;
  font-size: 45px;
  text-align: center;
}
.menuPage > .menuList > div > .itemList {
  padding-inline: 10%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
}
.menuPage > .menuList > div > .itemList > .item:nth-child(2n) {
  grid-template-columns: 3fr 4fr;
}
.menuPage > .menuList > div > .itemList > .item:nth-child(2n) > div {
  grid-column: 2/3;
  grid-row: 1/2;
}
.menuPage > .menuList > div > .itemList > .item:nth-child(2n) > div:last-child {
  grid-column: 1/2;
}
.menuPage > .menuList > div > .itemList > .item {
  display: grid;
  grid-template-columns: 4fr 3fr;
  align-items: start;
  width: 100%;
  height: 300px;
  gap: 30px;
}
.menuPage > .menuList > div > .itemList > .item > div:not(.blurLoad) {
  /* order: 2; */
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;
}
.menuPage > .menuList > div > .itemList > .item > div:not(.blurLoad) > h3 {
  font-family: PoppinsBold;
  font-size: 25px;
}
.menuPage > .menuList > div > .itemList > .item > div:not(.blurLoad) > p {
  font-family: PoppinsMedium;
  font-size: 15px;
  color: rgb(201, 201, 201);
}
.menuPage > .menuList > div > .itemList > .item > div:not(.blurLoad) > .price {
  color: var(--accent-color);
  font-family: PoppinsRegular;
}
.menuPage > .bossWoodie {
  display: flex;
  justify-content: center;
  color: white;
  width: 90%;
  margin-top: 2rem;
  margin-bottom: 12rem;
  margin-inline: auto;
  gap: 8rem;
}
.menuPage > .bossWoodie .section {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}
.menuPage > .bossWoodie .section .blurLoad {
  width: 70%;
}
.menuPage > .bossWoodie .section h2 {
  text-align: center;
  color: #fff;
  font-family: PoppinsMedium;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.menuPage > .bossWoodie .section .bossDogsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 3rem;
}
.menuPage > .bossWoodie .section .bossDogsWrapper .bossDog {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  width: 40%;
}
.menuPage > .bossWoodie .section .bossDogsWrapper .bossDog .top {
  display: flex;
  justify-content: space-between;
}
.menuPage > .bossWoodie .section .bossDogsWrapper .bossDog .top h3 {
  color: #fff;
  font-family: PoppinsMedium;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.menuPage > .bossWoodie .section .bossDogsWrapper .bossDog .top p {
  color: rgba(255, 255, 255, 0.8);
  font-family: PoppinsMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.menuPage > .bossWoodie .section .bossDogsWrapper .bossDog .bottom {
  color: rgba(255, 255, 255, 0.75);
  font-family: PoppinsMedium;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.menuPage > .bossWoodie .right .blurLoad {
  align-self: flex-end;
}
.menuPage > .bossWoodie .right .bossDogsWrapper {
  justify-content: flex-start;
}
.menuPage > .snacky {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-inline: 10%;
  padding-block: 50px;
}
.menuPage > .snacky > h2 {
  font-size: 45px;
  font-family: PoppinsBold;
  padding-bottom: 50px;
}
.menuPage > .snacky > .snackyList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 30px;
}
.menuPage > .snacky > .snackyList > div {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 0;
  font-family: PoppinsBold;
  font-size: 20px;
}
.menuPage > .snacky > .snackyList > div > span {
  font-family: PoppinsRegular;
  font-size: 16px;
  color: var(--accent-color);
}
.menuPage > .dezerty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: black;
  color: white;
  padding-block: 80px;
}
.menuPage > .dezerty > .title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menuPage > .dezerty > .title > h2 {
  font-family: PoppinsBold;
  font-size: 45px;
}
.menuPage > .dezerty > .title > p {
  font-family: PoppinsRegular;
  text-align: center;
  font-size: 15px;
}
.menuPage > .dezerty > .content {
  /* padding-inline: 25%; */
  display: flex;
  padding-inline: 10%;
  justify-content: center;
  gap: 40px;
}
.menuPage > .dezerty > .content > div {
  display: flex;
  align-items: center;
  position: relative;
}
.menuPage > .dezerty > .content > div > .blurLoad {
  height: 100%;
  width: 50%;
}
.menuPage > .dezerty > .content > div > .blurLoad > img {
  width: 100%;
}
.menuPage > .dezerty > .content > div > div {
  width: 100%;
  position: relative;
  height: 50%;
}
.menuPage > .dezerty > .content > div > div > h3 {
  font-family: PoppinsBold;
}
.menuPage > .dezerty > .content > div > div > p {
  font-family: PoppinsMedium;
  color: rgb(163, 163, 163);
}
.menuPage > .dezerty > .content > div > div > .price {
  color: var(--accent-color);
  font-family: PoppinsRegular;
}
.menuPage > .prilohyOmacky {
  display: flex;
  color: white;
  padding-inline: 10%;
  gap: 40px;
}
.menuPage > .prilohyOmacky > .prilohy {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  gap: 50px;
}
.menuPage > .prilohyOmacky > .prilohy > h2 {
  font-family: PoppinsBold;
  font-size: 40px;
}
.menuPage > .prilohyOmacky > .prilohy > .content {
  display: flex;
  gap: 40px;
  width: 100%;
}
.menuPage > .prilohyOmacky > .prilohy > .content > .left {
  flex-grow: 1;
  gap: 5px;
  display: flex;
  flex-direction: column;
}
.menuPage > .prilohyOmacky > .prilohy > .content > .left > .riadok {
  font-family: PoppinsRegular;
  font-size: 13px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.menuPage > .prilohyOmacky > .prilohy > .content > .right {
  flex-grow: 1;
  gap: 5px;
  display: flex;
  flex-direction: column;
}
.menuPage > .prilohyOmacky > .prilohy > .content > .right > .riadok {
  font-family: PoppinsRegular;
  font-size: 13px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.menuPage > .prilohyOmacky > .prilohy > .content > .left > .riadok:nth-child(2n) {
  color: rgb(146, 146, 146);
}
.menuPage > .prilohyOmacky > .prilohy > .content > .right > .riadok:nth-child(2n-1) {
  color: rgb(146, 146, 146);
}
.menuPage > .prilohyOmacky > .omacky {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.menuPage > .prilohyOmacky > .omacky > h2 {
  font-family: PoppinsBold;
  font-size: 40px;
}
.menuPage > .prilohyOmacky > .omacky > .blurLoad {
  width: 60%;
}
.menuPage > .prilohyOmacky > .omacky > .blurLoad > img {
  width: 100%;
}
.menuPage > .prilohyOmacky > .omacky > p {
  text-align: center;
  font-family: PoppinsRegular;
  font-size: 13px;
}
.menuPage > .prilohyOmacky > .omacky > .content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.menuPage > .prilohyOmacky > .omacky > .content > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menuPage > .prilohyOmacky > .omacky > .content > div > .blurLoad {
  width: 70%;
}
.menuPage > .prilohyOmacky > .omacky > .content > div > .blurLoad > img {
  width: 100%;
}
.menuPage > .prilohyOmacky > .omacky > .content > div > div {
  gap: 20px;
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.menuPage > .prilohyOmacky > .omacky > .content > div > div > p:first-child {
  font-family: PoppinsBold;
  font-size: 15px;
}
.menuPage > .prilohyOmacky > .omacky > .content > div > div > p:last-child {
  font-family: PoppinsRegular;
  color: var(--accent-color);
  font-size: 15px;
}

.menuPage > div:not(.menuTop) .blurLoad {
  background-position: center;
  background-size: cover;
}
.menuPage > div:not(.menuTop) .blurLoad > img {
  opacity: 0;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  order: 1;
  transition: opacity 300ms ease-in-out;
}
.menuPage > div:not(.menuTop) .blurLoad > .loaded {
  opacity: 1;
}

@media (width < 1400px) {
  .menuPage > .menuList > div > .itemList > .item {
    display: flex;
    flex-direction: column;
    height: auto;
  }
}
@media (width < 1100px) {
  .menuPage .bossWoodie .section .bossDogsWrapper {
    flex-direction: column;
    align-items: center;
  }
  .menuPage .bossWoodie .section .bossDogsWrapper .bossDog {
    width: 60%;
  }
  .menuPage .bossWoodie .section .blurLoad {
    width: 100%;
  }
  .menuPage > .dezerty {
    gap: 50px;
  }
  .menuPage > .dezerty > .content > div {
    flex-direction: column;
    gap: 30px;
  }
  .menuPage > .dezerty > .content > div > .blurLoad {
    width: 70%;
  }
  .menuPage > .prilohyOmacky {
    flex-direction: column;
    align-items: center;
  }
  .menuPage > .prilohyOmacky > .prilohy {
    width: -moz-fit-content;
    width: fit-content;
  }
  .menuPage > .prilohyOmacky > .prilohy > .content {
    gap: 100px;
  }
  .menuPage > .prilohyOmacky > .prilohy > .content > div > div {
    gap: 20px;
  }
  .menuPage > .prilohyOmacky > .omacky {
    width: 80%;
    /* >.content {
        display: flex;
        flex-direction: column;
        gap: 50px;

        >div>div{
            width: 100%;
        }
    } */
  }
  .menuPage > .prilohyOmacky > .omacky > .blurLoad {
    width: 100%;
  }
}
@media (width < 800px) {
  .menuPage > .menuTop {
    height: calc(100vh - 120px);
  }
  .menuPage > .menuTop > div > p {
    text-align: center;
  }
  .menuPage > .menuList > div > .itemList {
    grid-template-columns: 1fr;
  }
  .menuPage > .menuList > div > .itemList > .item {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .menuPage > .snacky > h2 {
    text-align: center;
  }
  .menuPage > .snacky > .snackyList {
    display: flex;
    flex-direction: column;
  }
  .menuPage > .snacky > .snackyList > div:not(.blurLoad) {
    text-wrap: balance;
  }
  .menuPage > .dezerty > .content {
    flex-direction: column;
  }
  .menuPage > .dezerty > .content > div {
    flex-direction: column;
    gap: 30px;
  }
  .menuPage > .dezerty > .content > div > .blurLoad {
    order: 2;
    width: 80%;
  }
}
@media (width < 650px) {
  .menuPage .bossWoodie {
    flex-direction: column;
    align-items: center;
  }
  .menuPage .bossWoodie .section {
    width: 100%;
  }
  .menuPage .menuTop > div > h1 {
    font-size: 110px;
  }
  .menuPage .menuTop > div > p {
    padding-inline: 10%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .menuPage > .prilohyOmacky {
    flex-direction: column;
  }
  .menuPage > .prilohyOmacky > .prilohy {
    width: 70%;
  }
  .menuPage > .prilohyOmacky > .prilohy > .content {
    gap: 0;
    flex-direction: column;
  }
  .menuPage > .prilohyOmacky > .omacky {
    width: 100%;
  }
  .menuPage > .prilohyOmacky > .omacky > .blurLoad {
    width: 100%;
  }
  .menuPage > .prilohyOmacky > .omacky > .content {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .menuPage > .prilohyOmacky > .omacky > .content > div > div {
    width: 100%;
  }
}
@media (width < 400px) {
  .menuPage > .prilohyOmacky > .prilohy {
    width: 90%;
  }
}/*# sourceMappingURL=MenuPage.css.map */
.homeMenu {
    background-image: url(../../img/homeMenuBG.jpg);
    overflow: hidden;
    background-size: cover;
    padding: 100px 15%;
    width: 100%;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 400px);
    grid-template-areas:
        "bossburger bossburger menu"
        "bossburger bossburger kontakt"
        "godfather lotus tower";

    > div:hover > div {
        scale: 1.08;
        filter: blur(5px);
    }

    > div {
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        position: relative;
        overflow: hidden;

        > .blurLoad {
            position: absolute;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            height: 100%;
            width: 100%;
            transition: scale 200ms ease-in-out, filter 200ms ease-in-out;

            > img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                opacity: 0;
                transition: opacity 300ms ease-in-out;
            }

            > .loaded {
                opacity: 1;
            }
        }

        > * {
            z-index: 1;
        }

        > h2 {
            font-size: 60px;
            font-family: var(--fontHomeMenu);
            text-align: center;
            -webkit-box-shadow: 0px 24px 0px -20px rgb(255, 255, 255);
            -moz-box-shadow: 0px 24px 0px -20px rgb(255, 255, 255);
            box-shadow: 0px 24px 0px -20px rgb(255, 255, 255);
        }

        > p {
            font-family: MollenPersonal;
            font-size: 25px;
        }

        > a {
            font-family: MollenPersonal;
            font-size: 17px;
            border: 1px solid white;
            padding: 10px;
            border-radius: 3px;
            color: white;
            transition: color 300ms ease-in-out, border-color 300ms ease-in-out, background-color 300ms ease-in-out;

            &:hover {
                // color: rgb(0, 0, 0);
                background-color: var(--accent-color);
                border-color: var(--accent-color);
            }
        }
    }

    > a {
        &:hover > div > div {
            scale: 1.08;
            filter: blur(5px);
        }
        > div {
            height: 100%;
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
            position: relative;
            overflow: hidden;

            > .blurLoad {
                position: absolute;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                height: 100%;
                width: 100%;
                transition: scale 200ms ease-in-out, filter 200ms ease-in-out;

                > img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    opacity: 0;
                    transition: opacity 300ms ease-in-out;
                }

                > .loaded {
                    opacity: 1;
                }
            }

            > * {
                z-index: 1;
            }

            > h2 {
                font-size: 60px;
                font-family: var(--fontHomeMenu);
                text-align: center;
                -webkit-box-shadow: 0px 24px 0px -20px rgb(255, 255, 255);
                -moz-box-shadow: 0px 24px 0px -20px rgb(255, 255, 255);
                box-shadow: 0px 24px 0px -20px rgb(255, 255, 255);
            }

            > p {
                font-family: MollenPersonal;
                font-size: 25px;
            }

            > a {
                font-family: MollenPersonal;
                font-size: 17px;
                border: 1px solid white;
                padding: 10px;
                border-radius: 3px;
                color: white;
                transition: color 300ms ease-in-out, border-color 300ms ease-in-out, background-color 300ms ease-in-out;

                &:hover {
                    // color: rgb(0, 0, 0);
                    background-color: var(--accent-color);
                    border-color: var(--accent-color);
                }
            }
        }
    }

    > .bossburger {
        grid-area: bossburger;

        /* >div {
            height: 100%;
            width: 100%;

            background-image: url(../../img/menuImages/10_resized.jpg);
            background-size: cover;
            background-position: center center;
        } */
    }

    > .menu {
        grid-area: menu;

        /* >div {
            height: 100%;
            width: 100%;
            background-image: url(../../img/menuImages/6_resized.jpg);
            background-size: cover;
            background-position: center center;

        } */
    }

    > .kontakt {
        grid-area: kontakt;

        /* >div {
            height: 100%;
            width: 100%;
            background-image: url(../../img/menuImages/14_resized.jpg);
            background-size: cover;
            background-position: center center;
        } */
    }

    > .tower {
        grid-area: tower;

        /* >div {
            height: 100%;
            width: 100%;
            background-image: url(../../img/menuImages/8_resized.jpg);
            background-size: cover;
            background-position: center center;
        } */
    }

    > .lotus {
        grid-area: lotus;

        /* >div {
            height: 100%;
            width: 100%;
            background-image: url(../../img/menuImages/3_resized.jpg);
            background-size: cover;
            background-position: center center;
        } */
    }

    > .godfather {
        grid-area: godfather;

        /* >div {
            height: 100%;
            width: 100%;
            background-image: url(../../img/menuImages/2_resized.jpg);
            background-size: cover;
            background-position: center center;
        } */
    }
}

@media (width < 800px) {
    .homeMenu {
        display: flex;
        flex-direction: column;
        padding-inline: 7%;

        > div,
        >a  {
            height: 350px;

            > h2 {
                font-size: 50px;
            }

            > p {
                font-size: 20px;
            }

            > a {
                font-size: 15px;
            }
        }
    }
}

.homeMenu {
  background-image: url(../../img/homeMenuBG.jpg);
  overflow: hidden;
  background-size: cover;
  padding: 100px 15%;
  width: 100%;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 400px);
  grid-template-areas: "bossburger bossburger menu" "bossburger bossburger kontakt" "godfather lotus tower";
}
.homeMenu > div:hover > div {
  scale: 1.08;
  filter: blur(5px);
}
.homeMenu > div {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  overflow: hidden;
}
.homeMenu > div > .blurLoad {
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  transition: scale 200ms ease-in-out, filter 200ms ease-in-out;
}
.homeMenu > div > .blurLoad > img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}
.homeMenu > div > .blurLoad > .loaded {
  opacity: 1;
}
.homeMenu > div > * {
  z-index: 1;
}
.homeMenu > div > h2 {
  font-size: 60px;
  font-family: var(--fontHomeMenu);
  text-align: center;
  box-shadow: 0px 24px 0px -20px rgb(255, 255, 255);
}
.homeMenu > div > p {
  font-family: MollenPersonal;
  font-size: 25px;
}
.homeMenu > div > a {
  font-family: MollenPersonal;
  font-size: 17px;
  border: 1px solid white;
  padding: 10px;
  border-radius: 3px;
  color: white;
  transition: color 300ms ease-in-out, border-color 300ms ease-in-out, background-color 300ms ease-in-out;
}
.homeMenu > div > a:hover {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}
.homeMenu > a:hover > div > div {
  scale: 1.08;
  filter: blur(5px);
}
.homeMenu > a > div {
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  overflow: hidden;
}
.homeMenu > a > div > .blurLoad {
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  transition: scale 200ms ease-in-out, filter 200ms ease-in-out;
}
.homeMenu > a > div > .blurLoad > img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}
.homeMenu > a > div > .blurLoad > .loaded {
  opacity: 1;
}
.homeMenu > a > div > * {
  z-index: 1;
}
.homeMenu > a > div > h2 {
  font-size: 60px;
  font-family: var(--fontHomeMenu);
  text-align: center;
  box-shadow: 0px 24px 0px -20px rgb(255, 255, 255);
}
.homeMenu > a > div > p {
  font-family: MollenPersonal;
  font-size: 25px;
}
.homeMenu > a > div > a {
  font-family: MollenPersonal;
  font-size: 17px;
  border: 1px solid white;
  padding: 10px;
  border-radius: 3px;
  color: white;
  transition: color 300ms ease-in-out, border-color 300ms ease-in-out, background-color 300ms ease-in-out;
}
.homeMenu > a > div > a:hover {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}
.homeMenu > .bossburger {
  grid-area: bossburger;
  /* >div {
      height: 100%;
      width: 100%;

      background-image: url(../../img/menuImages/10_resized.jpg);
      background-size: cover;
      background-position: center center;
  } */
}
.homeMenu > .menu {
  grid-area: menu;
  /* >div {
      height: 100%;
      width: 100%;
      background-image: url(../../img/menuImages/6_resized.jpg);
      background-size: cover;
      background-position: center center;

  } */
}
.homeMenu > .kontakt {
  grid-area: kontakt;
  /* >div {
      height: 100%;
      width: 100%;
      background-image: url(../../img/menuImages/14_resized.jpg);
      background-size: cover;
      background-position: center center;
  } */
}
.homeMenu > .tower {
  grid-area: tower;
  /* >div {
      height: 100%;
      width: 100%;
      background-image: url(../../img/menuImages/8_resized.jpg);
      background-size: cover;
      background-position: center center;
  } */
}
.homeMenu > .lotus {
  grid-area: lotus;
  /* >div {
      height: 100%;
      width: 100%;
      background-image: url(../../img/menuImages/3_resized.jpg);
      background-size: cover;
      background-position: center center;
  } */
}
.homeMenu > .godfather {
  grid-area: godfather;
  /* >div {
      height: 100%;
      width: 100%;
      background-image: url(../../img/menuImages/2_resized.jpg);
      background-size: cover;
      background-position: center center;
  } */
}

@media (width < 800px) {
  .homeMenu {
    display: flex;
    flex-direction: column;
    padding-inline: 7%;
  }
  .homeMenu > div,
  .homeMenu > a {
    height: 350px;
  }
  .homeMenu > div > h2,
  .homeMenu > a > h2 {
    font-size: 50px;
  }
  .homeMenu > div > p,
  .homeMenu > a > p {
    font-size: 20px;
  }
  .homeMenu > div > a,
  .homeMenu > a > a {
    font-size: 15px;
  }
}/*# sourceMappingURL=HomeMenu.css.map */
.pdf {
    height: 100vh;
    margin: 0;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block: 5vh;
    overflow: hidden;
    background-color: #0f0c0cf5;

    > h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: PoppinsBold;
        transition: opacity 300ms ease-in-out;
        color: white;
    }

    .book>.page{
        background-color: black;
    }

    >.desktop {
        // border: 1px solid white;
        transition: opacity 300ms ease-in-out;
        width: 80vw;
        aspect-ratio: 1200/650;
        position: relative;
        perspective: 2000px;
        opacity: 0;
        // -webkit-box-shadow: 0px 0px 100px -45px rgba(255,190,11,1);
        // -moz-box-shadow: 0px 0px 100px -45px rgba(255,190,11,1);
        // box-shadow: 0px 0px 100px -45px rgba(255,190,11,1);

        > .page {
            // border: 1px solid white;
            position: absolute;
            right: 0;
            height: 100%;
            width: 50%;
            transform-origin: left;
            transform-style: preserve-3d;
            backface-visibility: hidden;
            transition: transform 1000ms ease-in-out, right 300ms ease-in-out;
        }

        > .page:nth-child(2n) {
            transform-origin: right;
            right: 50%;
            transform: rotateY(180deg);
        }

        &.first {
            > .page {
                right: 25%;
            }
        }
    }

    >.mobile {
        opacity: 0;
        // margin-left: 5vw;
        gap: 20px;
        display: flex;
        width: fit-content;
        overflow: hidden;
        width: 90vw;
        position: relative;
        aspect-ratio: 600/650;

        >.page {
            width: 100%;
            position: absolute;
            transition: transform 500ms ease-in-out;
            inset: 0;
            transform: translateX(0);

            &.hidden {
                transform: translateX(-100%);
            }
            
        }
    }



    .controls {
        opacity: 0;
        width: fit-content;
        display: flex;
        justify-content: space-between;
        font-size: 30px;
        gap: 30px;
        margin-inline: auto;
        transition: opacity 300ms ease-in-out;
        margin-top: 30px;

        > button {
            outline: none;
            border: none;
            background-color: var(--accent-color);
            border-radius: 50%;
            aspect-ratio: 1;
            width: 50px;
            display: grid;
            place-items: center;
            transition: background-color 300ms ease-in-out, border 300ms ease-in-out;

            &:hover {
                border: 2px solid var(--accent-color);
                background-color: white;

                > svg {
                    fill: var(--accent-color);
                    transition: fill 300ms ease-in-out;
                }
            }

            > svg {
                fill: white;
            }
        }

        > p {
            color: white;
            font-family: PoppinsBold;
            font-size: 26px;
        }
    }
}


@media (orientation: landscape) {
    .pdf {
        >.mobile {
            height: 80vh;
            width: auto;
        }

    }
}



.aboutSection {
    display: flex;
    overflow: hidden;
    padding: 150px 20%;
    justify-content: space-between;


    >.left {
        display: flex;
        gap: 50px;
        flex-direction: column;
        width: 50%;

        >h2 {
            font-family: FutureHeadline;
            font-size: 40px;
        }

        >p {
            font-family: MollenPersonalRegular;
            font-size: 20px;
        }
    }

    >.right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        right: 50%;

        >h2 {
            font-family: MollenPersonalRegular;
            font-size: 20px;
        }

        >.top {
            font-family: OswaldRegular;
            display: flex;
            flex-direction: column;
            gap: 10px;

            >p {

                display: flex;
                width: 90%;
                justify-content: space-between;

                >span {
                    color: grey;
                    font-weight: 600;
                }
            }
        }

        >.bottom {

            >h2 {
                margin-bottom: 20px;
            }
        
            > * {
                font-family:MollenPersonalRegular;
            }
        }
    }
}

@media (width < 800px) {
    .aboutSection {
        flex-direction: column;
        gap: 50px;
        padding-inline: 7%;

        >.left {
            width: 100%;
        } 

        >.right {
            width: 100%;
            gap: 30px;
        }

    }
}
/* ---------------------------RESET-------------------------------- */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    cursor: none;
}

html,
body {
    height: 100%;
    scroll-behavior: smooth;
    /* overflow-x: hidden; */
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
}

/* ------------------------------------------------------------------------------- */

/* -----------------------Fonts---------------------------------- */

@font-face {
    font-family: TabacBigSlab;
    src: url(./fonts/TabacBigSlab-Black.ttf);
}

@font-face {
    font-family: TabacBigSlabBold;
    src: url(./fonts/TabacBigSlab-Bold.ttf);
}

@font-face {
    font-family: MollenPersonal;
    src: url(./fonts/Mollen-Personal-Use-Bold.otf);
}

@font-face {
    font-family: MollenPersonalRegular;
    src: url(./fonts/Mollen-Personal-Use-Regular.otf);
}

@font-face {
    font-family: PoppinsMedium;
    src: url(./fonts/Poppins-Medium.ttf);
}

@font-face {
    font-family: PoppinsBold;
    src: url(./fonts/Poppins-Bold.ttf);
}

@font-face {
    font-family: PoppinsRegular;
    src: url(./fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: OswaldRegular;
    src: url(./fonts/Oswald-Regular.ttf);
}

@font-face {
    font-family: FutureHeadline;
    src: url(./fonts/Monotype-Futura-Now-Headline-Bd.otf);
}

@font-face {
    font-family: TheBoldFont;
    src: url(./fonts/THEBOLDFONT.ttf);
}

/* ---------------------------------------------------------------------- */

/* ----------------------------Custom Properties------------------------------------ */

:root {
    --accent-color: #ffbe0b;
    --cursor: var(--accent-color);

    --fontHomeMenu: TabacBigSlabBold;
}

/* ------------------------------------------------------------------------------------------ */

/* ---------------------SCROLL-BAR---------------------------- */


/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: var(--accent-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
}
@media (width < 1000px) {
    ::-webkit-scrollbar {
        width: 5px;
    }
}

@media (width < 500px) {
    ::-webkit-scrollbar {
        display: none;
    }
}

/* -------------------------------------------------------- */

/* --------------------------CURSOR---------------------------- */

@media (hover:hover) {

    .cursor_dot {
        width: 10px;
        height: 10px;
        background-color: var(--cursor);
        transition: width 300ms ease-in-out, height 300ms ease-in-out, opacity 300ms ease-in-out;
        /* mix-blend-mode: difference; */
    }

    .cursor_dot.hidden {
      display: none;
    }
    
    .cursor_dot.cursor_dot_hover {
        /* width: 50px; */
        opacity: 0;
        /* height: 50px; */
    }
    
    .cursor_outline {
        /* mix-blend-mode: difference; */
        width: 20px;
        height: 20px;
        border: 2px solid var(--cursor);
    
        opacity: 1;
        transition: width 300ms ease-in-out, height 300ms ease-in-out, opacity 300ms ease-in-out;
    }

    .cursor_outline.hidden {
      display: none;
    }
    
    .cursor_outline_hover {
        /* opacity: 0; */
        width: 50px;
        height: 50px;
    }
    
    .cursor_dot,
    .cursor_outline {
        position: fixed;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        z-index: 10;
        pointer-events: none;
    }
}


/* ------------------------------------------------------------------ */


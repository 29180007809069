footer {
    padding-inline: 20%;
    padding-top: 100px;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-around;
    position: relative;
    background-color: white;


    > a > img {
        width: 150px;
    }

    > div a {
        width: fit-content;
        p {
            width: fit-content;
            font-family: MollenPersonalRegular;
            color: black;
            transition: scale 300ms ease-in-out;
        }
    }

    > div p:hover {
        scale: 1.1;
    }

    > div {
        display: flex;
        flex-direction: column;
        gap: 8px;

        > h2 {
            font-family: MollenPersonal;
        }
    }

    > .sledujteNas > div > a {
        display: flex;
        align-items: center;
        gap: 10px;
        font-family: MollenPersonalRegular;

        > svg {
            height: 20px;
        }
    }

    > .copyright {
        position: absolute;
        bottom: 5px;
        width: 100%;
        text-align: center;
        font-family: MollenPersonalRegular;
        color: black;
    }
}

@media (width < 1400px) {
    footer {
        padding-inline: 10%;
    }
}

@media (width < 1000px) {
    footer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 200px 200px;
        height: fit-content;
        width: fit-content;
        margin-inline: auto;
        gap: 50px;
        padding-block: 100px;

        > a {
            > img {
                height: 80%;
            }
        }
    }
}

@media (width < 500px) {
    footer {
        display: flex;
        flex-direction: column;
        height: auto;
        gap: 50px;
        padding-block: 100px;

        > a > img {
            width: 150px;
            justify-self: center;
        }

        > .copyright {
            left: 0;
            font-size: 14px;
        }
    }
}

.menuPage {
    background-color: black;
    overflow: hidden;

    > .menuTop {
        /* background-image: url(../../img/menuImages/9_resized.jpg);
        background-size: cover;
        background-color: black;
        background-repeat: no-repeat;
        background-position: bottom left; */
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        position: relative;

        > .blurLoad {
            width: 100%;
            /* height: calc(100vh - 150px); */
            height: 100%;
            position: absolute;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: bottom left;
            top: 0;
            background-color: black;

            > img {
                width: 100%;
                height: 100%;
                opacity: 0;
                object-fit: cover;
                transition: opacity 300ms ease-in-out;
            }

            > .loaded {
                opacity: 0.8;
            }
        }

        > div {
            z-index: 1;

            > h1 {
                z-index: 1;
                text-align: center;
                color: white;
                font-size: 130px;
                font-family: TheBoldFont;
            }

            > p {
                display: flex;
                gap: 15px;

                > a {
                    z-index: 1;
                    color: white;
                    font-family: PoppinsBold;
                    font-size: 30px;
                    transition: color 300ms ease-in-out;

                    &:hover {
                        color: var(--accent-color);
                    }
                }
            }
        }
    }

    > .menuList {
        padding-block: 70px;
        display: flex;
        flex-direction: column;
        gap: 100px;

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 100px;

            > h2 {
                color: white;
                font-family: PoppinsBold;
                font-size: 45px;
                text-align: center;
            }

            > .itemList {
                padding-inline: 10%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 80px;

                > .item:nth-child(2n) {
                    grid-template-columns: 3fr 4fr;

                    > div {
                        grid-column: 2/3;
                        grid-row: 1/2;

                        // > div >img {
                        //     transform: scaleX(-1);
                        // }
                    }

                    > div:last-child {
                        grid-column: 1/2;
                    }
                }

                > .item {
                    display: grid;
                    grid-template-columns: 4fr 3fr;
                    align-items: start;
                    width: 100%;
                    height: 300px;
                    gap: 30px;

                    > div:not(.blurLoad) {
                        /* order: 2; */
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        color: white;

                        > h3 {
                            font-family: PoppinsBold;
                            font-size: 25px;
                        }

                        > p {
                            font-family: PoppinsMedium;
                            font-size: 15px;
                            color: rgb(201, 201, 201);
                        }

                        > .price {
                            // position: absolute;
                            // top: 0;
                            // right: 0;
                            color: var(--accent-color);
                            font-family: PoppinsRegular;
                        }
                    }
                }
            }
        }
    }

    > .bossWoodie {
        display: flex;
        // justify-content: space-between;
        justify-content: center;
        color: white;
        width: 90%;
        margin-top: 2rem;
        margin-bottom: 12rem;
        margin-inline: auto;
        gap: 8rem;

        .section {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4rem;

            .blurLoad {
                width: 70%;
            }

            h2 {
                text-align: center;
                color: #fff;
                font-family: PoppinsMedium;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .bossDogsWrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                gap: 3rem;

                .bossDog {
                    gap: 1rem;
                    display: flex;
                    flex-direction: column;
                    width: 40%;

                    .top {
                        display: flex;
                        justify-content: space-between;

                        h3 {
                            color: #fff;
                            font-family: PoppinsMedium;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }

                        p {
                            color: rgba(255, 255, 255, 0.8);
                            font-family: PoppinsMedium;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                        }
                    }

                    .bottom {
                        color: rgba(255, 255, 255, 0.75);
                        font-family: PoppinsMedium;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }
        }
        .right {
            .blurLoad {
                align-self: flex-end;
            }

            .bossDogsWrapper {
                justify-content: flex-start;
            }
        }
    }

    > .snacky {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        padding-inline: 10%;
        padding-block: 50px;

        > h2 {
            font-size: 45px;
            font-family: PoppinsBold;
            padding-bottom: 50px;
        }

        > .snackyList {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: 30px;

            > div {
                display: flex;
                justify-content: center;
                text-align: center;
                flex-direction: column;
                gap: 0;
                font-family: PoppinsBold;
                font-size: 20px;

                > span {
                    font-family: PoppinsRegular;
                    font-size: 16px;
                    color: var(--accent-color);
                }
            }
        }
    }

    > .dezerty {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        background-color: black;
        color: white;
        padding-block: 80px;

        > .title {
            display: flex;
            flex-direction: column;
            align-items: center;

            > h2 {
                font-family: PoppinsBold;
                font-size: 45px;
            }

            > p {
                font-family: PoppinsRegular;
                text-align: center;
                font-size: 15px;
            }
        }

        > .content {
            /* padding-inline: 25%; */
            display: flex;
            padding-inline: 10%;
            justify-content: center;
            gap: 40px;

            > div {
                display: flex;
                align-items: center;
                position: relative;

                > .blurLoad {
                    height: 100%;
                    width: 50%;

                    > img {
                        width: 100%;
                    }
                }

                > div {
                    width: 100%;
                    position: relative;
                    height: 50%;

                    > h3 {
                        font-family: PoppinsBold;
                    }

                    > p {
                        font-family: PoppinsMedium;
                        color: rgb(163, 163, 163);
                    }

                    > .price {
                        color: var(--accent-color);
                        font-family: PoppinsRegular;
                    }
                }
            }
        }
    }

    > .prilohyOmacky {
        display: flex;
        color: white;
        padding-inline: 10%;
        gap: 40px;

        > .prilohy {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            gap: 50px;

            > h2 {
                font-family: PoppinsBold;
                font-size: 40px;
            }

            > .content {
                display: flex;
                gap: 40px;
                width: 100%;

                > .left {
                    flex-grow: 1;
                    gap: 5px;
                    display: flex;
                    flex-direction: column;

                    > .riadok {
                        font-family: PoppinsRegular;
                        font-size: 13px;
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                    }
                }

                > .right {
                    flex-grow: 1;
                    gap: 5px;
                    display: flex;
                    flex-direction: column;

                    > .riadok {
                        font-family: PoppinsRegular;
                        font-size: 13px;
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                    }
                }
                $farba: rgb(146, 146, 146);

                > .left {
                    > .riadok:nth-child(2n) {
                        color: $farba;
                    }
                }
                > .right {
                    > .riadok:nth-child(2n-1) {
                        color: $farba;
                    }
                }
            }
        }

        > .omacky {
            width: 50%;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 20px;

            > h2 {
                font-family: PoppinsBold;
                font-size: 40px;
            }

            > .blurLoad {
                width: 60%;

                > img {
                    width: 100%;
                }
            }

            > p {
                text-align: center;
                font-family: PoppinsRegular;
                font-size: 13px;
            }

            > .content {
                display: grid;
                grid-template-columns: 1fr 1fr;

                > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    > .blurLoad {
                        width: 70%;

                        > img {
                            width: 100%;
                        }
                    }

                    > div {
                        gap: 20px;
                        display: flex;
                        justify-content: space-between;
                        width: 80%;

                        > p:first-child {
                            font-family: PoppinsBold;
                            font-size: 15px;
                        }

                        > p:last-child {
                            font-family: PoppinsRegular;
                            color: var(--accent-color);
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}

.menuPage > div:not(.menuTop) .blurLoad {
    background-position: center;
    background-size: cover;

    > img {
        opacity: 0;
        width: 100%;
        object-fit: cover;
        order: 1;
        transition: opacity 300ms ease-in-out;
    }

    > .loaded {
        opacity: 1;
    }
}

@media (width < 1400px) {
    .menuPage {
        > .menuList {
            > div {
                > .itemList {
                    > .item {
                        display: flex;
                        flex-direction: column;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media (width < 1100px) {
    .menuPage {
        .bossWoodie {
            .section {
                .bossDogsWrapper {
                    flex-direction: column;
                    align-items: center;

                    .bossDog {
                        width: 60%;
                    }
                }

                .blurLoad {
                    width: 100%;
                }
            }
        }

        > .dezerty {
            gap: 50px;
            > .content {
                > div {
                    flex-direction: column;
                    gap: 30px;

                    > .blurLoad {
                        width: 70%;
                    }
                }
            }
        }

        > .prilohyOmacky {
            flex-direction: column;
            align-items: center;

            > .prilohy {
                width: fit-content;
                > .content {
                    gap: 100px;

                    > div > div {
                        gap: 20px;
                    }
                }
            }

            > .omacky {
                width: 80%;

                > .blurLoad {
                    width: 100%;
                }

                /* >.content {
                    display: flex;
                    flex-direction: column;
                    gap: 50px;

                    >div>div{
                        width: 100%;
                    }
                } */
            }
        }
    }
}

@media (width < 800px) {
    .menuPage {
        > .menuTop {
            height: calc(100vh - 120px);

            > div > p {
                text-align: center;
            }
        }

        > .menuList {
            > div {
                > .itemList {
                    grid-template-columns: 1fr;

                    > .item {
                        display: flex;
                        flex-direction: column;
                        height: auto;
                    }
                }
            }
        }

        > .snacky {
            > h2 {
                text-align: center;
            }

            > .snackyList {
                display: flex;
                flex-direction: column;

                > div:not(.blurLoad) {
                    text-wrap: balance;
                }
            }
        }

        > .dezerty {
            > .content {
                flex-direction: column;

                > div {
                    flex-direction: column;
                    gap: 30px;

                    > .blurLoad {
                        order: 2;
                        width: 80%;
                    }
                }
            }
        }
    }
}

@media (width < 650px) {
    .menuPage {
        .bossWoodie {
            flex-direction: column;
            align-items: center;

            .section {
                width: 100%;
            }
        }

        .menuTop {
            > div {
                > h1 {
                    font-size: 110px;
                }

                > p {
                    padding-inline: 10%;
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
        }

        > .prilohyOmacky {
            flex-direction: column;

            > .prilohy {
                width: 70%;
                > .content {
                    gap: 0;
                    flex-direction: column;
                }
            }

            > .omacky {
                width: 100%;

                > .blurLoad {
                    width: 100%;
                }

                > .content {
                    display: flex;
                    flex-direction: column;
                    gap: 50px;

                    > div > div {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (width < 400px) {
    .menuPage {
        > .prilohyOmacky {
            > .prilohy {
                width: 90%;
            }
        }
    }
}

.homeAboutSection {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: black;
  padding-block: 100px;
  align-items: center;
  gap: 30px;
}
.homeAboutSection > img {
  width: 300px;
}
.homeAboutSection > h2 {
  color: white;
  font-size: 25px;
  font-family: MollenPersonal;
}
.homeAboutSection > a {
  border: 2px solid white;
  color: white;
  padding: 10px;
  border-radius: 2px;
  font-family: MollenPersonal;
  transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out;
}
.homeAboutSection > a:hover {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
}

@media (width < 800px) {
  .homeAboutSection > img {
    width: 300px;
  }
  .homeAboutSection > h2 {
    text-align: center;
  }
}/*# sourceMappingURL=HomeAboutSection.css.map */
.topSection {
  /* background-image: url(../../img/menuImages/9_blur.jpg);
  background-size: cover;
  background-color: black;
  background-repeat: no-repeat;
  background-position: bottom left; */
  overflow: hidden;
  height: 100vh;
  display: grid;
  place-items: center;
  position: relative;
}
.topSection > .blurLoad {
  width: 100%;
  /* height: calc(100vh - 150px); */
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom left;
  top: 0;
  background-color: black;
  z-index: -1;
}
.topSection > .blurLoad > img {
  width: 100%;
  height: 100%;
  opacity: 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 300ms ease-in-out;
}
.topSection > .blurLoad > .loaded {
  opacity: 0.8;
}
.topSection > div > .logo {
  height: 300px;
}

.loaded {
  background-image: url(../../img/menuImages/9_resized.jpg);
}

@media (width < 800px) {
  .topSection > div > .logo {
    height: 300px;
  }
}/*# sourceMappingURL=TopSection.css.map */
.topSection {
    /* background-image: url(../../img/menuImages/9_blur.jpg);
    background-size: cover;
    background-color: black;
    background-repeat: no-repeat;
    background-position: bottom left; */
    // height: calc(100vh - 150px);
    overflow: hidden;
    height: 100vh;
    display: grid;
    place-items: center;
    position: relative;

    > .blurLoad {
        width: 100%;
        /* height: calc(100vh - 150px); */
        height: 100%;
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom left;
        top: 0;
        background-color: black;
        z-index: -1;

        >img {
            width: 100%;
            height: 100%;
            opacity: 0;
            object-fit: cover;
            transition: opacity 300ms ease-in-out;
        }

        >.loaded {
            opacity: .8;
        }
    }

    >div>.logo {
        height: 300px;
    }
}

.loaded {
    background-image: url(../../img/menuImages/9_resized.jpg);
}


@media (width < 800px) {

    .topSection {
        // height: calc(100vh - 120px);
        >div>.logo {
            height: 300px;
        }
    }
}

.pdf {
  height: 100vh;
  margin: 0;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: 5vh;
  overflow: hidden;
  background-color: rgba(15, 12, 12, 0.9607843137);
}
.pdf > h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: PoppinsBold;
  transition: opacity 300ms ease-in-out;
  color: white;
}
.pdf .book > .page {
  background-color: black;
}
.pdf > .desktop {
  transition: opacity 300ms ease-in-out;
  width: 80vw;
  aspect-ratio: 1200/650;
  position: relative;
  perspective: 2000px;
  opacity: 0;
}
.pdf > .desktop > .page {
  position: absolute;
  right: 0;
  height: 100%;
  width: 50%;
  transform-origin: left;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transition: transform 1000ms ease-in-out, right 300ms ease-in-out;
}
.pdf > .desktop > .page:nth-child(2n) {
  transform-origin: right;
  right: 50%;
  transform: rotateY(180deg);
}
.pdf > .desktop.first > .page {
  right: 25%;
}
.pdf > .mobile {
  opacity: 0;
  gap: 20px;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  overflow: hidden;
  width: 90vw;
  position: relative;
  aspect-ratio: 600/650;
}
.pdf > .mobile > .page {
  width: 100%;
  position: absolute;
  transition: transform 500ms ease-in-out;
  inset: 0;
  transform: translateX(0);
}
.pdf > .mobile > .page.hidden {
  transform: translateX(-100%);
}
.pdf .controls {
  opacity: 0;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  gap: 30px;
  margin-inline: auto;
  transition: opacity 300ms ease-in-out;
  margin-top: 30px;
}
.pdf .controls > button {
  outline: none;
  border: none;
  background-color: var(--accent-color);
  border-radius: 50%;
  aspect-ratio: 1;
  width: 50px;
  display: grid;
  place-items: center;
  transition: background-color 300ms ease-in-out, border 300ms ease-in-out;
}
.pdf .controls > button:hover {
  border: 2px solid var(--accent-color);
  background-color: white;
}
.pdf .controls > button:hover > svg {
  fill: var(--accent-color);
  transition: fill 300ms ease-in-out;
}
.pdf .controls > button > svg {
  fill: white;
}
.pdf .controls > p {
  color: white;
  font-family: PoppinsBold;
  font-size: 26px;
}

@media (orientation: landscape) {
  .pdf > .mobile {
    height: 80vh;
    width: auto;
  }
}/*# sourceMappingURL=Pdf.css.map */
.rezervacie {
  overflow: hidden;
}
.rezervacie > .rezervacieTop {
  /* background-image: url(../../img/menuImages/9_resized.jpg);
  background-size: cover;
  background-color: black;
  background-repeat: no-repeat;
  background-position: bottom left; */
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.rezervacie > .rezervacieTop > .blurLoad {
  width: 100%;
  /* height: calc(100vh - 150px); */
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom left;
  top: 0;
  background-color: black;
  z-index: -1;
}
.rezervacie > .rezervacieTop > .blurLoad > img {
  width: 100%;
  height: 100%;
  opacity: 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 300ms ease-in-out;
}
.rezervacie > .rezervacieTop > .blurLoad > .loaded {
  opacity: 0.8;
}
.rezervacie > .rezervacieTop > div > h1 {
  color: white;
  font-size: 130px;
  font-family: TheBoldFont;
}
.rezervacie > .content {
  display: flex;
  padding: 100px 20%;
  justify-content: space-between;
  background-color: white;
}
.rezervacie > .content > .left {
  display: flex;
  gap: 30px;
  flex-direction: column;
  width: 50%;
}
.rezervacie > .content > .left > h2 {
  font-family: FutureHeadline;
  font-size: 40px;
}
.rezervacie > .content > .left > .contact {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-family: FutureHeadline;
  font-size: 20px;
}
.rezervacie > .content > .left > .contact > a {
  color: black;
  font-family: MollenPersonalRegular;
}
.rezervacie > .content > .left > p {
  font-family: MollenPersonalRegular;
  font-size: 20px;
}
.rezervacie > .content > .left > a {
  color: black;
}
.rezervacie > .content > .left > a > p {
  font-family: MollenPersonal;
  font-size: 25px;
}
.rezervacie > .content > .right {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
  right: 50%;
}
.rezervacie > .content > .right > h2 {
  font-family: MollenPersonalRegular;
  font-size: 20px;
}
.rezervacie > .content > .right > .top {
  font-family: OswaldRegular;
}
.rezervacie > .content > .right > .top > p {
  display: flex;
  width: 90%;
  justify-content: space-between;
}
.rezervacie > .content > .right > .top > p > span {
  color: grey;
  font-weight: 600;
}
.rezervacie > .content > .right > .bottom > h2 {
  margin-bottom: 20px;
}
.rezervacie > .content > .right > .bottom > * {
  font-family: MollenPersonalRegular;
}

.reservationFormWrapper {
  width: 100%;
  margin-inline: auto;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 100px;
}
.reservationFormWrapper > iframe {
  height: 800px;
}

@media (width < 500px) {
  .reservationFormWrapper > iframe {
    height: 900px;
  }
}
@media (width < 800px) {
  .rezervacie > .rezervacieTop {
    height: calc(100vh - 120px);
  }
  .rezervacie > .rezervacieTop > div > h1 {
    font-size: 60px;
  }
  .rezervacie > .content {
    flex-direction: column;
    gap: 50px;
    padding-inline: 7%;
  }
  .rezervacie > .content > .left {
    width: 100%;
  }
  .rezervacie > .content > .right {
    width: 100%;
    gap: 30px;
  }
}/*# sourceMappingURL=Rezervacie.css.map */
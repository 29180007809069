.aboutSection {
  display: flex;
  overflow: hidden;
  padding: 150px 20%;
  justify-content: space-between;
}
.aboutSection > .left {
  display: flex;
  gap: 50px;
  flex-direction: column;
  width: 50%;
}
.aboutSection > .left > h2 {
  font-family: FutureHeadline;
  font-size: 40px;
}
.aboutSection > .left > p {
  font-family: MollenPersonalRegular;
  font-size: 20px;
}
.aboutSection > .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  right: 50%;
}
.aboutSection > .right > h2 {
  font-family: MollenPersonalRegular;
  font-size: 20px;
}
.aboutSection > .right > .top {
  font-family: OswaldRegular;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.aboutSection > .right > .top > p {
  display: flex;
  width: 90%;
  justify-content: space-between;
}
.aboutSection > .right > .top > p > span {
  color: grey;
  font-weight: 600;
}
.aboutSection > .right > .bottom > h2 {
  margin-bottom: 20px;
}
.aboutSection > .right > .bottom > * {
  font-family: MollenPersonalRegular;
}

@media (width < 800px) {
  .aboutSection {
    flex-direction: column;
    gap: 50px;
    padding-inline: 7%;
  }
  .aboutSection > .left {
    width: 100%;
  }
  .aboutSection > .right {
    width: 100%;
    gap: 30px;
  }
}/*# sourceMappingURL=AboutSection.css.map */
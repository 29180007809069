.desktopNav {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  transition: max-height 300ms ease-in-out;
}
.desktopNav.sticky {
  max-height: 70px;
}
.desktopNav.sticky > .upperNav {
  translate: 0 -100%;
}
.desktopNav.sticky > .bottomNav {
  transform: translateY(-51px);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.227);
}
.desktopNav .upperNav {
  transition: translate 300ms ease-in-out;
  display: flex;
  justify-content: center;
  position: relative;
  padding-block: 15px;
  background-color: white;
}
.desktopNav .upperNav .contact {
  display: flex;
  font-family: PoppinsMedium;
  font-size: 13px;
  font-weight: 500;
  gap: 20px;
  color: red;
}
.desktopNav .upperNav .contact > div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.desktopNav .upperNav .contact > div > svg {
  height: 16px;
  transition: fill 300ms ease-in-out;
}
.desktopNav .upperNav .contact > div > a > p {
  color: black;
  transition: color 300ms ease-in-out;
}
.desktopNav .upperNav .social {
  display: flex;
  position: absolute;
  right: 50px;
  height: 25px;
  gap: 20px;
}
.desktopNav .upperNav .social > a > svg {
  height: 100%;
  transition: fill 300ms ease-in-out;
}
.desktopNav .bottomNav {
  background-color: black;
  display: flex;
  max-height: 100px;
  padding-inline: 5%;
  padding-block: 10px;
  align-items: center;
  color: white;
  justify-content: space-between;
  transition: background-color 300ms ease-in-out, transform 300ms ease-in-out, -webkit-backdrop-filter 300ms ease-in-out;
  transition: background-color 300ms ease-in-out, transform 300ms ease-in-out, backdrop-filter 300ms ease-in-out;
  transition: background-color 300ms ease-in-out, transform 300ms ease-in-out, backdrop-filter 300ms ease-in-out, -webkit-backdrop-filter 300ms ease-in-out;
}
.desktopNav .bottomNav > a {
  height: 70px;
}
.desktopNav .bottomNav > a > img {
  max-height: 70px;
  transition: max-height 300ms ease-in-out, margin-top 300ms ease-in-out;
}
.desktopNav .bottomNav > ul {
  display: flex;
  gap: 60px;
}
.desktopNav .bottomNav > ul > li > a.active > p::after {
  width: 100%;
  left: 0;
}
.desktopNav .bottomNav > ul > li > a > p::after {
  background: none repeat scroll 0 0 transparent;
  bottom: -10;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: var(--accent-color);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.desktopNav .bottomNav > ul > li > a:hover > p::after {
  width: 100%;
  left: 0;
}
.desktopNav .bottomNav > ul > li > a > p {
  position: relative;
  color: white;
  font-size: 20px;
  font-family: OswaldRegular;
}
.desktopNav .bottomNav > ul > .changeLanguage {
  -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none; /* Standard syntax */
  display: flex;
  font-size: 20px;
  font-family: OswaldRegular;
}

.mobileNav {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.mobileNav .upperNav {
  display: flex;
  justify-content: space-around;
  position: relative;
  padding-block: 15px;
  height: 40px;
}
.mobileNav .upperNav .contact {
  display: flex;
  font-family: PoppinsMedium;
  font-weight: 500;
  gap: 20px;
  color: red;
}
.mobileNav .upperNav .contact > div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.mobileNav .upperNav .contact > div > svg {
  height: 10px;
  transition: fill 300ms ease-in-out;
}
.mobileNav .upperNav .contact > div > a > p {
  color: black;
  font-size: 10px;
  transition: color 300ms ease-in-out;
}
.mobileNav .upperNav .social {
  display: flex;
  right: 50px;
  height: 15px;
  gap: 20px;
}
.mobileNav .upperNav .social > a > svg {
  height: 100%;
  transition: fill 300ms ease-in-out;
}
.mobileNav .bottomNav {
  background-color: black;
  display: flex;
  height: 80px;
  padding-inline: 5%;
  align-items: center;
  color: white;
  justify-content: space-between;
}
.mobileNav .bottomNav > a {
  height: 70%;
  z-index: 3;
}
.mobileNav .bottomNav > a > img {
  height: 100%;
}
.mobileNav .bottomNav > .changeLanguage {
  display: flex;
  -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none; /* Standard syntax */
  font-size: 20px;
  font-family: OswaldRegular;
}
.mobileNav .bottomNav > .menuIcon {
  width: 20px;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;
}
.mobileNav .bottomNav > .menuIcon > span {
  display: block;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  height: 20%;
  background: #fff;
  transition: all 0.3s;
  position: relative;
}
.mobileNav .bottomNav > .active > span:nth-child(1) {
  animation: ease 0.7s top forwards;
}
.mobileNav .bottomNav > .notActive > span:nth-child(1) {
  animation: ease 0.7s top-2 forwards;
}
.mobileNav .bottomNav > .active > span:nth-child(2) {
  animation: ease 0.7s scaled forwards;
}
.mobileNav .bottomNav > .notActive > span:nth-child(2) {
  animation: ease 0.7s scaled-2 forwards;
}
.mobileNav .bottomNav > .active > span:nth-child(3) {
  animation: ease 0.7s bottom forwards;
}
.mobileNav .bottomNav > .notActive > span:nth-child(3) {
  animation: ease 0.7s bottom-2 forwards;
}
.mobileNav > .mobile {
  position: fixed;
  inset: 0;
  background-color: black;
  opacity: 0;
  z-index: -1;
  transition: opacity 500ms ease-in-out, z-index 500ms ease-in-out;
  display: grid;
  place-content: center;
}
.mobileNav > .mobile > ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.mobileNav > .mobile > ul > li > a {
  text-decoration: none;
  color: white;
}
.mobileNav > .mobile > ul > li > a > p {
  font-family: TheBoldFont;
  font-size: 40px;
}
.mobileNav > .mobile.active {
  opacity: 1;
  z-index: 2;
}
.mobileNav > .mobile.active > ul > li:nth-child(1) > a > p {
  animation: navSlideIn 600ms ease-in-out forwards;
}
.mobileNav > .mobile.active > ul > li:nth-child(2) > a > p {
  animation: navSlideIn 800ms ease-in-out forwards;
}
.mobileNav > .mobile.active > ul > li:nth-child(3) > a > p {
  animation: navSlideIn 1000ms ease-in-out forwards;
}
.mobileNav > .mobile.active > ul > li:nth-child(4) > a > p {
  animation: navSlideIn 1200ms ease-in-out forwards;
}
.mobileNav > .mobile.active > ul > li:nth-child(5) > a > p {
  animation: navSlideIn 1400ms ease-in-out forwards;
}

@keyframes navSlideIn {
  from {
    margin-left: 300px;
  }
  to {
    margin-left: 0;
  }
}
@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 6px;
    transform: rotate(0);
  }
  100% {
    top: 6px;
    transform: rotate(45deg);
  }
}
@keyframes top-2 {
  0% {
    top: 5.5px;
    transform: rotate(45deg);
  }
  50% {
    top: 5.5px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}
@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 5.5px;
    transform: rotate(0);
  }
  100% {
    bottom: 10px;
    transform: rotate(135deg);
  }
}
@keyframes bottom-2 {
  0% {
    bottom: 10px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 5.5px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}
@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}/*# sourceMappingURL=Navbar.css.map */
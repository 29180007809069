.rezervacie {
    overflow: hidden;
    > .rezervacieTop {
        /* background-image: url(../../img/menuImages/9_resized.jpg);
        background-size: cover;
        background-color: black;
        background-repeat: no-repeat;
        background-position: bottom left; */
        position: relative;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        > .blurLoad {
            width: 100%;
            /* height: calc(100vh - 150px); */
            height: 100%;
            position: absolute;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: bottom left;
            top: 0;
            background-color: black;
            z-index: -1;

            > img {
                width: 100%;
                height: 100%;
                opacity: 0;
                object-fit: cover;
                transition: opacity 300ms ease-in-out;
            }

            > .loaded {
                opacity: 0.8;
            }
        }

        > div > h1 {
            color: white;
            font-size: 130px;
            font-family: TheBoldFont;
        }
    }

    > .content {
        display: flex;
        padding: 100px 20%;
        justify-content: space-between;
        background-color: white;

        > .left {
            display: flex;
            gap: 30px;
            flex-direction: column;
            width: 50%;

            > h2 {
                font-family: FutureHeadline;
                font-size: 40px;
            }

            > .contact {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                font-family: FutureHeadline;
                font-size: 20px;

                > a {
                    color: black;
                    font-family: MollenPersonalRegular;
                }
            }

            > p {
                font-family: MollenPersonalRegular;
                font-size: 20px;
            }

            > a {
                color: black;

                > p {
                    font-family: MollenPersonal;
                    font-size: 25px;
                }
            }
        }

        > .right {
            margin-top: 100px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 2rem;
            right: 50%;

            > h2 {
                font-family: MollenPersonalRegular;
                font-size: 20px;
            }

            > .top {
                font-family: OswaldRegular;

                > p {
                    display: flex;
                    width: 90%;
                    justify-content: space-between;

                    > span {
                        color: grey;
                        font-weight: 600;
                    }
                }
            }

            > .bottom {
                > h2 {
                    margin-bottom: 20px;
                }

                > * {
                    font-family: MollenPersonalRegular;
                }
            }
        }
    }
}

.reservationFormWrapper {
    width: 100%;
    margin-inline: auto;
    height: fit-content;
    margin-bottom: 100px;

    > iframe {
        height: 800px;
    }
}

@media (width < 500px) {
    .reservationFormWrapper {
        > iframe {
            height: 900px;
        }
    }
}

@media (width < 800px) {
    .rezervacie {
        > .rezervacieTop {
            height: calc(100vh - 120px);

            > div > h1 {
                font-size: 60px;
            }
        }

        > .content {
            flex-direction: column;
            gap: 50px;
            padding-inline: 7%;

            > .left {
                width: 100%;
            }

            > .right {
                width: 100%;

                gap: 30px;
            }
        }
    }
}
